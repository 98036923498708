import React, { useState } from 'react';
import { auth, googleLogin } from 'services/firebase';

import 'assets/css/forms.css';

import gLogo from 'assets/img/g-logo.png';

const Register = () => {
	const [inputs, setInputs] = useState({
		name: '',
		email: '',
		password: '',
	});

	const emailSignup = async (e) => {
		try {
			const userCredential = await auth.createUserWithEmailAndPassword(
				inputs.email,
				inputs.password
			);

			await userCredential.user.updateProfile({
				displayName: inputs.name,
			});

			window.location.href = '/newProfile';
		} catch (error) {
			console.error(error);
		}
	};

	const googleSignin = async () => {
		await googleLogin();

		window.location.href = '/newProfile';
	};

	const handleChange = ({ target }) =>
		setInputs({ ...inputs, [target.name]: target.value });

	return (
		<div className='form'>
			<div className='form-group'>
				<label htmlFor='name'>Име</label>
				<input
					type='text'
					name='name'
					placeholder='Џон Доу'
					onChange={handleChange}
					value={inputs.name}
				/>
			</div>
			<div className='form-group'>
				<label htmlFor='email'>Меил</label>
				<input
					type='email'
					name='email'
					placeholder='email@example.com'
					onChange={handleChange}
					value={inputs.email}
				/>
			</div>
			<div className='form-group'>
				<label htmlFor='password'>Лозинка</label>
				<input
					type='password'
					name='password'
					placeholder='*******'
					onChange={handleChange}
					value={inputs.password}
				/>
			</div>
			<div className='buttons'>
				<button className='btn' onClick={emailSignup}>
					Регистрирај се
				</button>
				<button className='g-login btn' onClick={googleSignin}>
					<img src={gLogo} className='logo-img' />
					<span>Логирај се со Google</span>
				</button>
			</div>
		</div>
	);
};

export default Register;
