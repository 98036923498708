import React, { useState, useEffect } from 'react';
import PostCard from './PostCard';
import Comment from './Comment';

import { db, auth, currTimestamp } from 'services/firebase';

const datesAreOnSameDay = (first, second) =>
	first.getFullYear() === second.getFullYear() &&
	first.getMonth() === second.getMonth() &&
	first.getDate() === second.getDate();

const formatDate = (date) => {
	const current = new Date();

	return datesAreOnSameDay(current, date)
		? date.toLocaleTimeString()
		: date.toLocaleDateString();
};

const ViewPost = ({ match: { params } }) => {
	const [post, setPost] = useState(null);
	const [inputs, setInputs] = useState({
		content: '',
	});

	const [user, setUser] = useState(null);
	const [profile, setProfile] = useState(null);
	auth.onAuthStateChanged(async (user) => {
		try {
			if (!user) return;
			setUser(user);
			if (!profile) {
				const { uid } = user;

				const docRef = db.collection('users').doc(uid);
				const doc = await docRef.get();

				if (doc.exists) setProfile(doc.data());
				else
					throw {
						code: 'NonExistent',
						message: `Document /users/${uid} doesn't exist`,
					};
			}
		} catch (error) {
			console.error(error);
		}
	});

	useEffect(async () => {
		try {
			const docRef = db.collection('forum').doc(params.postId);
			const doc = await docRef.get();

			console.log('got', doc.data());
			if (doc.exists) setPost(doc.data());
			else
				throw {
					code: 'NonExistent',
					message: `Document /forum/${params.postId} doesn't exist`,
				};
		} catch (error) {
			console.error(error);
		}
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const docRef = db.collection('forum').doc(params.postId);

			await docRef.set({
				...post,
				comments: [
					...post.comments,
					{
						...inputs,
						madeBy: {
							uid: user.uid,
							username: profile.username,
						},
						commented: currTimestamp(),
					},
				],
			});

			window.location.reload();
		} catch (error) {
			console.error(error);
		}
	};

	const handleChange = ({ target }) =>
		setInputs({ ...inputs, [target.name]: target.value });

	return (
		<>
			<PostCard post={post} />
			<form onSubmit={handleSubmit} className='new-comment-form'>
				<div className='inline-form-group'>
					<label htmlFor='content'>Коментар</label>
					<input
						type='text'
						name='content'
						placeholder='Awesome comment'
						onChange={handleChange}
						value={inputs.content}
					/>
				</div>
				<input type='submit' value='Коментирај' className='btn' />
			</form>
			{post &&
				post.comments
					.reverse()
					.map((comment, i) => <Comment comment={comment} key={i} />)}
		</>
	);
};

export default ViewPost;
