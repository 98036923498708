import React from 'react';
import { Link } from 'react-router-dom';

import pawHeart from 'assets/img/paw-heart.svg';
import 'assets/css/post.css';

const datesAreOnSameDay = (first, second) =>
	first.getFullYear() === second.getFullYear() &&
	first.getMonth() === second.getMonth() &&
	first.getDate() === second.getDate();

const formatDate = (date) => {
	const current = new Date();

	return datesAreOnSameDay(current, date)
		? date.toLocaleTimeString()
		: date.toLocaleDateString();
};

const PostCard = ({ post, id }) => {
	return (
		<>
			{post ? (
				<div className='post'>
					<div className='made-bar'>
						<Link to={`/users/${post.madeBy.username}`} className='author-link'>
							<b>@{post.madeBy.username}</b>
						</Link>
						<span className='posted-date'>
							{formatDate(post.posted.toDate())}
						</span>
					</div>
					<div className='content-wrap'>
						<h1 className='title'>{post.title}</h1>
						<p className='content'>{post.content}</p>
					</div>
					{id ? (
						<Link to={`/forum/post/${id}`}>
							<p>
								<i class='far fa-comment-alt'></i> Comments
							</p>
						</Link>
					) : null}
				</div>
			) : null}
		</>
	);
};

export default PostCard;
