import React, { useState } from 'react';
import { auth, db } from 'services/firebase';

import 'assets/css/forms.css';

const NewProfile = () => {
	const [user, setUser] = useState(null);
	const [inputs, setInputs] = useState({
		username: '',
		bio: '',
	});

	auth.onAuthStateChanged((user) => {
		if (user) setUser(user);
	});

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const userRef = db.collection('users').doc(user.uid);

			await userRef.set({ ...inputs, pets: [] });

			window.location.href = '/';
		} catch (error) {
			console.error(error);
		}
	};

	const handleChange = ({ target }) =>
		setInputs({ ...inputs, [target.name]: target.value });

	return (
		<form onSubmit={handleSubmit} className='form'>
			<div className='form-group'>
				<label htmlFor='username'>Корисничко име</label>
				<input
					type='text'
					name='username'
					placeholder='john_doe'
					onChange={handleChange}
					value={inputs.username}
				/>
			</div>
			<div className='form-group'>
				<label htmlFor='bio'>Кратка биографија</label>
				<textarea
					name='bio'
					className='textarea-width'
					onChange={handleChange}
					value={inputs.bio}
				/>
			</div>
			<div className='buttons'>
				<input type='submit' className='btn' value='Создади профил' />
			</div>
		</form>
	);
};

export default NewProfile;
