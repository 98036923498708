import React, { useState } from 'react';
import { auth } from 'services/firebase';

import LoggedIn from './LoggedIn';
import NotLoggedIn from './NotLoggedIn';

const Landing = () => {
	const [user, setUser] = useState(null);

	auth.onAuthStateChanged((user) => {
		if (user) setUser(user);
	});

	return <>{user ? <LoggedIn user={user} /> : <NotLoggedIn />}</>;
};

export default Landing;
