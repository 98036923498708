import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from 'services/firebase';

import 'assets/css/navigation.css';

const LoggedInLinks = () => {
	return (
		<>
			<li>
				<Link to='/logout'>Logout</Link>
			</li>
		</>
	);
};

const NotLoggedInLinks = () => {
	return (
		<>
			<li>
				<Link to='/login'>Sign in</Link>
			</li>
			<li>
				<Link to='/register'>Регистрација</Link>
			</li>
		</>
	);
};

const Navigation = () => {
	const [user, setUser] = useState(null);

	auth.onAuthStateChanged((user) => {
		if (user) setUser(user);
	});

	return (
		<nav className='navbar'>
			<ul>
				<li>
					<Link to='/'>🏡 Дома</Link>
				</li>
				<li>
					<Link to='/forum'>Форум</Link>
				</li>
				<li>
					<Link to='/poisonRegions'>Отровни региони</Link>
				</li>
				{user ? <LoggedInLinks /> : <NotLoggedInLinks />}
			</ul>
		</nav>
	);
};

export default Navigation;
