import React, { useState } from 'react';
import { customAlphabet } from 'nanoid';

import { db, currTimestamp, auth } from 'services/firebase';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 8);

const NewPost = () => {
	const [inputs, setInputs] = useState({
		title: '',
		content: '',
	});

	const [user, setUser] = useState(null);
	const [profile, setProfile] = useState(null);
	auth.onAuthStateChanged(async (user) => {
		try {
			if (!user) return;
			setUser(user);
			if (!profile) {
				const { uid } = user;

				const docRef = db.collection('users').doc(uid);
				const doc = await docRef.get();

				if (doc.exists) setProfile(doc.data());
				else
					throw {
						code: 'NonExistent',
						message: `Document /users/${uid} doesn't exist`,
					};
			}
		} catch (error) {
			console.error(error);
		}
	});

	const handleSubmit = async (e) => {
		e.preventDefault();

		const { username } = profile;
		const { uid } = user;
		try {
			const docId = nanoid();
			const docData = {
				...inputs,
				posted: currTimestamp(),
				madeBy: Object.assign({}, { username, uid }),
				comments: [],
			};

			await db.collection('forum').doc(docId).set(docData);

			window.location.href = `/forum/post/${docId}`;
		} catch (error) {
			console.error(error);
		}
	};

	const handleChange = ({ target }) =>
		setInputs({ ...inputs, [target.name]: target.value });

	return (
		<form onSubmit={handleSubmit} className='form'>
			<div className='form-group'>
				<label htmlFor='title'>Наслов</label>
				<input
					type='text'
					name='title'
					placeholder='Awesome title'
					onChange={handleChange}
					value={inputs.title}
				/>
			</div>
			<div className='form-group'>
				<label htmlFor='content'>Содржина</label>
				<textarea
					name='content'
					className='textarea-width'
					onChange={handleChange}
					value={inputs.content}
				/>
			</div>
			<div className='buttons'>
				<input type='submit' className='btn' value='Постирај' />
			</div>
		</form>
	);
};

export default NewPost;
