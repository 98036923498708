import React from 'react';

import 'assets/css/style.css';

import Routes from 'components/Routes';

const App = () => {
	return (
		<>
			<Routes />
		</>
	);
};

export default App;
