import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from 'components/layout/Navigation';
import Login from 'components/auth/Login';
import Logout from 'components/auth/Logout';
import Register from 'components/auth/Register';
import NewProfile from 'components/auth/NewProfile';
import Landing from 'components/Landing/index';
import Forum from 'components/Forum/LatestPosts';
import NewPost from 'components/Forum/NewPost';
import ViewPost from 'components/Forum/ViewPost';
import PoisonRegions from 'components/PoisonRegions';

const Routes = () => {
	return (
		<Router>
			<Navigation />
			<main>
				<Route exact path='/'>
					<Landing />
				</Route>
				<Route exact path='/forum'>
					<Forum />
				</Route>
				<Route exact path='/forum/post/:postId' component={ViewPost} />
				<Route exact path='/newPost'>
					<NewPost />
				</Route>
				<Route exact path='/poisonRegions'>
					<PoisonRegions />
				</Route>
				<Route exact path='/login'>
					<Login />
				</Route>
				<Route exact path='/register'>
					<Register />
				</Route>
				<Route exact path='/logout'>
					<Logout />
				</Route>
				<Route exact path='/newProfile'>
					<NewProfile />
				</Route>
			</main>
		</Router>
	);
};

export default Routes;
