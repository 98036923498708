import React from 'react';

import 'assets/css/pet.css';

const Pet = ({
	setInputs,
	inputs,
	editable,
	options: { name, bio, age, sex, breed },
	handleRemove,
	index,
}) => {
	const handleChange = ({ target }) =>
		setInputs({ ...inputs, [target.name]: target.value });

	return (
		<div className='pet-info'>
			<i
				className='fas fa-minus-circle'
				onClick={() => handleRemove(index)}
			></i>
			<header>
				<h3>
					{editable ? (
						<input
							type='text'
							name='name'
							className='name-input'
							onChange={handleChange}
							value={inputs.name}
							placeholder='Име'
						/>
					) : (
						<>{name}</>
					)}
				</h3>
				<p>
					{editable ? (
						<input
							type='text'
							name='bio'
							className='bio-input'
							onChange={handleChange}
							value={inputs.bio}
							placeholder='Кратка биографија'
						/>
					) : (
						<>{bio}</>
					)}
				</p>
			</header>
			<div className='info-wrap'>
				<div className='pet-info-card'>
					<p className='value'>
						{editable ? (
							<input
								type='number'
								name='age'
								className='age-input'
								onChange={handleChange}
								value={inputs.age}
							/>
						) : (
							<>{age} години</>
						)}
					</p>
					<p className='key'>Возраст</p>
				</div>
				<div className='pet-info-card'>
					<p className='value'>
						{editable ? (
							<input
								type='text'
								name='breed'
								className='breed-input'
								onChange={handleChange}
								value={inputs.breed}
							/>
						) : (
							<>{breed}</>
						)}
					</p>
					<p className='key'>Раса</p>
				</div>
				<div className='pet-info-card'>
					<p className='value'>
						{editable ? (
							<select
								name='sex'
								className='sex-input'
								onChange={handleChange}
								value={inputs.sex}
							>
								<option value='male'>Машки</option>
								<option value='female'>Женски</option>
							</select>
						) : (
							<>{sex === 'female' ? 'Женски' : 'Машки'}</>
						)}
					</p>
					<p className='key'>Пол</p>
				</div>
			</div>
		</div>
	);
};

export default Pet;
