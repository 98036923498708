import React, { useState } from 'react';

import { auth } from 'services/firebase';

const Logout = () => {
	const [signedOut, setSignedOut] = useState(false);

	(async () => {
		await auth.signOut();
		window.location.href = '/login';
	})();

	return <p>Signing you out...</p>;
};

export default Logout;
