import React from 'react';
import { Link } from 'react-router-dom';

const NotLoggedIn = () => {
	return (
		<>
			<h2>Здраво!</h2>
			<p>
				За да се регистрирате како нов корисник стиснете го линкот горе или{' '}
				<Link to='/register'>тука</Link>. Може да се регистрирате со вашиот меил
				и пасворд, но и со Google account. После регистрацијата ќе треба да
				внесете корисничко име и кратка биографија за да направите профил кои
				другите луѓе ке можат да го посетат.
			</p>
			<p>
				За да додадете едно од вашите миленичиња на профилот притиснете го
				копчето "Ново милениче", потоа внесете ги бараните податоци за
				миленичето.
			</p>
			<p>
				Во делот "форум" може да постирате или да коментирате на пост од друг
				корисник.
			</p>
			<p>
				За да разгледате каде има локации каде е пријавен отров за животни,
				отидете на линкот отровни региони. За да пријавите места со отров,
				најдете ги географската ширина и должина од локацијата која сакате да ја
				внесете и напишете ја во формат "латитуда, лонгитуда".
			</p>
		</>
	);
};

export default NotLoggedIn;
