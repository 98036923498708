import React, { useState } from 'react';
import { auth, googleLogin } from 'services/firebase';

import 'assets/css/forms.css';

import gLogo from 'assets/img/g-logo.png';

const Login = () => {
	const [inputs, setInputs] = useState({
		email: '',
		password: '',
	});

	const emailLogin = async (e) => {
		try {
			await auth.signInWithEmailAndPassword(inputs.email, inputs.password);
		} catch (error) {
			console.error(error);
		}
	};

	const gLogin = async () => {
		try {
			await googleLogin();
		} catch (error) {
			console.error(error);
		}
	};

	const handleChange = ({ target }) =>
		setInputs({ ...inputs, [target.name]: target.value });

	auth.onAuthStateChanged((currUser) => {
		if (currUser) {
			window.location.href = '/';
		}
	});

	return (
		<div className='form'>
			<div className='form-group'>
				<label htmlFor='email'>Меил</label>
				<input
					type='email'
					name='email'
					placeholder='email@example.com'
					onChange={handleChange}
					value={inputs.email}
				/>
			</div>
			<div className='form-group'>
				<label htmlFor='password'>Лозинка</label>
				<input
					type='password'
					name='password'
					placeholder='*******'
					onChange={handleChange}
					value={inputs.password}
				/>
			</div>
			<div className='buttons'>
				<button className='btn' onClick={emailLogin}>
					Логирај се
				</button>
				<button className='g-login btn' onClick={gLogin}>
					<img src={gLogo} className='logo-img' />
					<span>Логирај се со Google</span>
				</button>
			</div>
		</div>
	);
};

export default Login;
