import React, { useState } from 'react';

import { db, firebase } from 'services/firebase';

const PoisonForm = () => {
	const [inputs, setInputs] = useState({ coords: '', danger: 'medium' });

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const [latitude, longitude] = inputs.coords.split(new RegExp(', +'));
			console.log(latitude, longitude);

			await db.collection('poison').add({
				danger: inputs.danger,
				location: new firebase.firestore.GeoPoint(latitude, longitude),
			});

			window.location.reload();
		} catch (error) {
			console.error(error);
		}
	};

	const handleChange = ({ target }) =>
		setInputs({ ...inputs, [target.name]: target.value });

	return (
		<section className='report-section'>
			<h3>Пријавете отровен регион</h3>
			<form className='report-form' onSubmit={handleSubmit} className='form'>
				<div className='form-group'>
					<label htmlFor='coords'>Позиција (гео. должина, ширина):</label>
					<input
						type='text'
						name='coords'
						placeholder='41.9981, 21.4254'
						onChange={handleChange}
						value={inputs.coords}
					/>
				</div>
				<div className='form-group'>
					<label htmlFor='danger'>Опасност</label>
					<select name='danger' onChange={handleChange} value={inputs.danger}>
						<option value='low'>Ниска</option>
						<option value='medium'>Средна</option>
						<option value='high'>Многу опасно</option>
					</select>
				</div>
				<div className='buttons'>
					<input type='submit' className='btn' value='Пријави' />
				</div>
			</form>
		</section>
	);
};

export default PoisonForm;
