import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import ReportForm from './PoisonForm';

import { db } from 'services/firebase';

import 'assets/css/map.css';

const measure = (
	{ latitude: lat1, longitude: lon1 },
	{ latitude: lat2, longitude: lon2 }
) => {
	const R = 6378.137;
	const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
	const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos((lat1 * Math.PI) / 180) *
			Math.cos((lat2 * Math.PI) / 180) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const d = R * c;
	return d * 1000;
};

const PoisonBubble = ({ danger }) => <div className={`danger-${danger}`}></div>;

const PoisonRegions = () => {
	const [userCoords, setUserCoords] = useState(null);
	const [poisonCoords, setPoisonCoords] = useState([]);

	navigator.geolocation.getCurrentPosition((position) =>
		setUserCoords(position.coords)
	);

	useEffect(async () => {
		const docsSnapshots = await db.collection('poison').get();
		const poisonArr = [];
		docsSnapshots.forEach((poison, i) => {
			poisonArr.push(poison.data());
		});
		setPoisonCoords(poisonArr);
	}, []);

	const [danger, setDanger] = useState({ distance: Infinity });

	return (
		<>
			<div className='map-wrap'>
				{userCoords ? (
					<GoogleMapReact
						defaultCenter={{ lat: 41.9991964, lng: 21.3546789 }}
						defaultZoom={13}
						options={{ scrollwheel: false }}
					>
						{poisonCoords.map(({ location, danger: dangerLevel }, i) => {
							const distance = measure(location, userCoords);

							if (distance < danger.distance)
								setDanger({ distance, level: dangerLevel });

							return (
								<PoisonBubble
									lat={location.latitude}
									lng={location.longitude}
									danger={dangerLevel}
									key={i}
								/>
							);
						})}
					</GoogleMapReact>
				) : (
					<p>
						За да може да видите кои региони се најблиску до вас дадете ни
						пристап до вашата локација.
					</p>
				)}
			</div>
			<p>
				{danger.distance < 750 ? (
					<>
						<div className={`danger-bubble level-${danger.level}`}></div>
						{`Пазете ги миленичињата! Најблиску до вас има регион со штетен отров на само ${parseInt(
							danger.distance
						)}м.`}
					</>
				) : (
					`Најблискиот регион со штетен отров е на ${parseInt(
						danger.distance
					)}м од вас.`
				)}
			</p>
			<ReportForm />
		</>
	);
};

export default PoisonRegions;
