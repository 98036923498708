import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from 'services/firebase';

import Pet from 'components/Pets/Pet.js';

import 'assets/css/profile.css';

const LoggedIn = ({ user }) => {
	const [profile, setProfile] = useState(null);
	const [pets, setPets] = useState([]);
	const [petInputs, setPetInputs] = useState({
		age: 0,
		breed: null,
		sex: 'female',
		bio: '',
		name: '',
	});
	const [editing, setEditing] = useState(true);

	useEffect(async () => {
		try {
			const { uid } = user;

			const docRef = db.collection('users').doc(uid);
			const doc = await docRef.get();

			if (doc.exists) {
				const data = doc.data();
				setProfile(data);
				setPets(data.pets);
			} else
				throw {
					code: 'NonExistent',
					message: `Document /users/${uid} doesn't exist`,
				};
		} catch (error) {
			console.error(error);
		}
	}, []);

	const finishAddition = async () => {
		try {
			const userRef = db.collection('users').doc(user.uid);
			const userDoc = (await userRef.get()).data();

			await userRef.set({
				...userDoc,
				pets: [
					...userDoc.pets,
					{
						...petInputs,
					},
				],
			});

			window.location.reload();
		} catch (error) {
			console.error(error);
		}
	};

	const newPet = () => {
		setPets([
			...pets,
			{
				name: 'Марта',
				bio: 'Кратка биографија тука.',
				age: 3,
				sex: 'Женски',
				breed: 'Nebelung',
				editable: true,
			},
		]);
	};

	const removePet = async (index) => {
		try {
			pets.splice(index, 1);
			setPets(pets);

			const userRef = db.collection('users').doc(user.uid);

			await userRef.set({
				...profile,
				pets,
			});

			window.location.reload();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<section className='profile'>
				{profile ? (
					<>
						<header>
							<img src={user.photoURL} />
							<h1>{user.displayName}</h1>@{profile.username}
						</header>
						<p className='bio'>
							<b>Bio:</b>
							<br />
							{profile.bio}
						</p>
					</>
				) : (
					<>
						<p>No profile :(</p>
						<Link to='/newProfile'>Make one here</Link>
					</>
				)}
			</section>

			<h2>Миленичиња</h2>
			<section className='pets-view'>
				{pets.map((pet, i) => (
					<Pet
						options={pet}
						key={i}
						index={i}
						setInputs={setPetInputs}
						inputs={petInputs}
						handleRemove={removePet}
						{...(pet.editable ? { editable: true } : {})}
					/>
				))}
			</section>
			<div className='new-pet-wrap'>
				<button className='btn' onClick={newPet}>
					Ново милениче
				</button>
				{editing ? (
					<button
						style={{ marginLeft: '1em' }}
						className='btn white'
						onClick={finishAddition}
					>
						Сочувај
					</button>
				) : null}
			</div>
		</>
	);
};

export default LoggedIn;
