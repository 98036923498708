import firebase from 'firebase';

const firebaseConfig = {
	apiKey: 'AIzaSyAIxpI9PtxtSdEX1ZgmCqgA753anmWIMVs',
	authDomain: 'cool-pets.firebaseapp.com',
	projectId: 'cool-pets',
	storageBucket: 'cool-pets.appspot.com',
	messagingSenderId: '223954433408',
	appId: '1:223954433408:web:28adce39aea49a71593d65',
	measurementId: 'G-PDRNDJF10T',
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
auth.languageCode = 'mk';

export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const db = firebase.firestore();

export const googleLogin = async () =>
	auth
		.signInWithPopup(googleProvider)
		.then((res) => {
			console.log('logged in user', res.user.displayName);
		})
		.catch((error) => {
			console.log(error.message);
		});

export const currTimestamp = firebase.firestore.Timestamp.now;

export { firebase, auth };
