import React from 'react';
import { Link } from 'react-router-dom';

const datesAreOnSameDay = (first, second) =>
	first.getFullYear() === second.getFullYear() &&
	first.getMonth() === second.getMonth() &&
	first.getDate() === second.getDate();

const formatDate = (date) => {
	const current = new Date();

	return datesAreOnSameDay(current, date)
		? date.toLocaleTimeString()
		: date.toLocaleDateString();
};

const Comment = ({ comment }) => {
	return (
		<div className='post'>
			<div className='made-bar'>
				<Link to={`/users/${comment.madeBy.username}`} className='author-link'>
					<b>@{comment.madeBy.username}</b>
				</Link>
				<span className='posted-date'>
					{formatDate(comment.commented.toDate())}
				</span>
			</div>
			<div className='content-wrap'>
				<p className='content'>{comment.content}</p>
			</div>
		</div>
	);
};

export default Comment;
