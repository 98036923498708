import React, { useState, useEffect } from 'react';
import PostCard from './PostCard';
import { Link } from 'react-router-dom';
import { db } from 'services/firebase';

import 'assets/css/forum.css';

const LatestPosts = () => {
	const [posts, setPosts] = useState([]);

	useEffect(async () => {
		const docsSnapshots = await db
			.collection('forum')
			.orderBy('posted', 'desc')
			.get();
		const postsArr = [];
		docsSnapshots.forEach((post, i) => {
			postsArr.push({ id: post.id, data: post.data() });
		});
		setPosts(postsArr);
	}, []);

	return (
		<>
			<div className='new-post-wrap'>
				<Link to='/newPost' className='btn new-post-btn'>
					<i class='fas fa-plus-square'></i>
					<span>Направете нов пост</span>
				</Link>
			</div>
			{posts.map((post, i) => {
				return <PostCard key={i} post={post.data} id={post.id} />;
			})}
		</>
	);
};

export default LatestPosts;
